.content {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding-top: 10vh;
  height: 100%;
  min-height: calc(100vh - 240px);
  @media screen and (max-width: 768px) {
    padding: 1rem;
    padding-top: 80px;
  }
}
.settingsItem {
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: var(--color-secondary);
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
}
.historySettingsContent {
  display: flex;
  gap: 1rem;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  &:hover {
    .topCopy {
      opacity: 1;
    }
  }
}
.copyButton {
  position: absolute;
  top: 5px;
  right: -30px;
  @media screen and (max-width: 768px) {
    right: 0px;
  }
}
.contentWrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
  margin-top: 2rem;
  @media screen and (max-width: 768px) {
    padding-right: 20px;
  }
}
.contentMD {
  // font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 1.2rem;
  line-height: 1.6;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  padding-bottom: 3rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.6;
  }
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    // white-space: pre-line;
    & > code {
      font-family: inherit;
      background: rgba(135, 131, 120, 0.15);
      color: #eb5757;
      border-radius: 3px;
      font-size: 85%;
      padding: 0.2em 0.4em;
    }
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.4rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.02);
    }
    tr:hover {
      background-color: rgba(255, 255, 255, 0.02);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.contentMD ul,
.contentMD ol {
  padding: 1.2rem;
  padding-top: 0;
  padding-left: 3.2rem;
}
.contentMD ol > li {
  list-style: numeric;
}
// .contentMD ol ul {
//   list-style: circle;
// }
.contentMD li + li {
  margin-top: 0.5rem;
}
.assistantInfo {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  width: 100%;
}
.assistantInfoAi {
  font-weight: 500;
  opacity: 0.6;
  font-size: 0.695rem;
  text-align: center;
}
.assistantInfoTitle {
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}
.assistantInfoDescription {
  text-align: center;
  color: var(--color-secondary);
}
