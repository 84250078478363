.drawer {
  background-color: var(--color-surface) !important;
  color: var(--color-text);
  font-size: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  overflow-y: auto;
}

.wrapper {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  header {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h2 {
      font-weight: 600;
      font-size: 1.2rem;
    }
    button {
      width: 30px;
      height: 30px;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--color-secondary);
      &:hover {
        opacity: 0.8;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.body {
  box-sizing: border-box;
  padding: 1rem;
  height: 100%;
}
