.container {
  box-sizing: border-box;
  padding-bottom: 100px;
  position: relative;
  height: 100%;
}
.label {
  font-size: 0.785rem;
  color: var(--color-secondary);
}

.content {
  margin-bottom: 0.7rem;
}
.title {
  font-weight: bold;
  margin-top: 1rem;
}

.contentFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.7rem;
  button {
    height: 28px;
  }
}
.footer {
  box-sizing: border-box;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: calc(0px + env(safe-area-inset-bottom, 0px));
  left: 0;
  right: 0;
  button {
    padding: 0;
    span {
      color: red;
      font-size: 1rem;
    }
  }
}
.socials {
  display: flex;
  gap: 1rem;
}
.links {
  margin-bottom: 0.5rem;
  ul {
    display: flex;
    margin-left: 0;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  li {
    list-style: none;
    a {
      color: var(--color-secondary);
      font-size: 0.785rem;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
