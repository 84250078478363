.container {
  padding-bottom: 5rem;
}

.form {
  box-sizing: border-box;
}
.results {
  margin-top: 1rem;
}
.resultsList {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.action {
  display: flex;
  justify-content: center;
}

.emptyPinsList {
  font-size: 0.875rem;
  color: var(--color-secondary);
}
