.container {
  box-sizing: border-box;
  text-align: left;
  max-width: 350px;
}

.credits {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    // color: var(--color-primary);
  }
  span {
    font-size: 0.785rem;
    color: var(--color-secondary);
    line-height: 1;
  }
}
.buy {
  label {
    button {
      width: auto;
      height: auto;
    }
  }
  span {
    top: 15px;
    font-size: 0.925rem;
  }
  input {
    height: 50px;
    font-size: 1rem;
  }
  button {
    height: 50px;
    margin-right: -10px;
    width: 60px;
    justify-content: center;
  }
  button span {
    padding-top: 0;
    margin-top: 0;
    color: #fff;
  }
}

.buyDetails {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  p {
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
}
