.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (min-width: 768px) {
    max-height: 100vh;
    overflow: hidden;
  }

  & > * {
    flex-grow: 1;
  }
}
.section {
  overflow-y: auto;
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    position: relative;
    z-index: 0;
  }
}

.form {
  width: 360px;
  box-sizing: border-box;
  padding: 0 0.3rem;
  max-width: 360px;
  min-width: 360px;
  border-right: 1px solid var(--color-input-bg);
  height: calc(100vh - 57px);
  max-height: calc(100vh - 57px);
  position: sticky;
  top: 0;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.zenHeaderWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}
.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.userSection {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  & > button {
    align-self: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
