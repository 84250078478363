.header {
  box-sizing: border-box;
  padding: 1rem 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promo {
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  background-color: var(--color-primary);
}
.promoWrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  p {
    font-size: 0.825rem;
    line-height: 1.2;
    color: var(--color-white);
  }
  a {
    display: flex;
  }
  img {
    max-height: 28px;
    vertical-align: baseline;
  }
}
.auth {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
  input + button {
    margin-left: 2rem;
    border: none;
    background-color: transparent;
  }
  label + button {
    margin-left: 2rem;
    border: none;
    background-color: transparent;
  }
}
.logo {
  display: flex;
  svg {
    width: 140px;
  }
}
nav {
  display: flex;
  align-items: center;
  //margin-left: 3rem;
  ul {
    margin-left: 2rem;
  }
}
.nav {
  display: flex;
  align-items: center;
  li {
    margin: 0.25rem;
    font-size: 0.875rem;
  }
  li {
    list-style: none;
  }
  a {
    display: inline-block;
    padding: 0.15rem 0.55rem;
    color: var(--color-secondary);
    font-weight: 500;
    border-radius: var(--border-radius-s);
    &:hover {
      background: var(--color-background-secondary);
    }
  }
}

@media screen and (max-width: 930px) {
  .logo svg {
    height: 30px;
    width: auto;
  }
  nav ul {
    margin-left: 1rem;
  }
  .nav li {
    margin: 0.15rem;
  }
}

.mobileAction {
  display: flex;
  align-items: center;
}

.mobileActionButtons {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  button {
    margin-left: 0.5rem;
  }
}

.hamburger {
  button {
    width: 38px;
    height: 38px;
    text-align: center;
    display: flex;
    justify-content: center;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  right: 0;
  box-sizing: border-box;
  padding: 1rem 0;
  background: var(--color-surface);
}

.drawerAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 38px;
    height: 38px;
    text-align: center;
    display: flex;
    justify-content: center;
    svg {
      width: 22px;
      height: 22px;
    }
  }
}
.drawerLanguage {
  margin-top: 2rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawerNav {
  margin-top: 2rem;
  li {
    margin-bottom: 1rem;
    a {
      font-weight: 500;
      font-size: 1.2rem;
    }
  }
}

.promo {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  background: var(--color-primary);
}
.promoWrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  a {
    color: #fff !important;
    display: inline;
    span {
      margin: 0 5px;
      border-radius: 4px;
      background-color: #fff;
      color: var(--color-primary) !important;
    }
  }
}

@media screen and (max-width: 768px) {
  a {
    font-size: 0.825rem;
    line-height: 18px;
  }
}
