.surface {
  background: var(--modal-surface);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.closeButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  button {
    width: 30px;
    background: black;
    border-radius: 4px;
    height: 30px;
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.2);
    justify-content: center;
  }
}
.container {
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: var(--modal-bg);
  border-radius: var(--border-radius-s);
  box-shadow: var(--popup-shadow-sm);
  box-sizing: border-box;
  padding: 1rem;
  // max-width: 350px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  min-width: 300px;
}
.icon {
  margin-bottom: 0.5rem;
}
.title {
  font-size: 1.15rem;
  font-weight: 500;
}

.subtitle {
  margin-top: 0.5rem;
  color: var(--color-grey);
}

.action {
  margin-top: 1rem;
  box-sizing: border-box;
  display: flex;
}
.action button + button {
  margin-left: 0.5rem;
}
.action button {
  flex-grow: 1;
  text-align: center;
  justify-content: center;
}
