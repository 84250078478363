.content {
  min-width: 500px;
  text-align: left;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
  h1 {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
.body {
  margin: 1rem 0;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    border: 1px solid var(--color-card-border);
    background: #1e1c1c;
  }

  h4 {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      text-transform: uppercase;
      color: var(--color-primary);
      padding: 0.1rem 0.3rem;
      line-height: 1;
      margin-left: 0.3rem;
      border-radius: var(--border-radius-s);
      font-size: 0.7rem;
      font-weight: 500;
      background-color: rgba(63, 136, 197, 0.2);
    }
  }
  p {
    font-size: 0.825rem !important;
    margin-bottom: 0.6rem;
  }

  h3 {
    font-size: 2.2rem;
    text-align: left;
    line-height: 1.2;
    font-weight: 600;
    span {
      font-size: 1rem;
      color: var(--color-secondary);
      font-weight: 400;
    }
  }
}

.premiumSection {
  padding: 1rem;
  border-color: var(--color-primary) !important;
}
.freeSection {
  padding: 1rem;
}
.actionButton {
  margin-top: 125px;
}
.advantages {
  margin-bottom: 1rem;
  margin-top: 1rem;
  p {
    font-size: 0.825rem !important;
    margin-bottom: 0.6rem;
    //color: var(--color-secondary);
    svg {
      vertical-align: top;
      margin-right: 5px;
      max-width: 14px;
    }
  }
}
