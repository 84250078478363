.item {
  border-radius: var(--border-radius-s);
  //background-color: var(--color-card-bg);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  padding-top: 0.65rem;
  display: flex;
  flex-direction: column;
  position: relative;
  h2 {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.4;
  }

  &:hover {
    border-color: var(--color-primary);
    .headerActions {
      opacity: 1;
    }
  }
}
.ai {
  font-size: 0.725rem;
  color: var(--color-secondary);
}
.title {
  margin-top: 0.3rem;
  font-weight: 500;
}
.description {
  font-size: 0.875rem;
  color: var(--color-secondary);
  line-height: 1.2;
  margin-top: 0.5rem;
}
.date {
  margin-top: 1rem;
  font-size: 0.725rem;
  color: var(--color-secondary);
}
.active {
  border-color: var(--color-primary);
}
.actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  button {
    height: 28px;
  }
}
