.ldsellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.ldsellipsis div {
  position: absolute;
  top: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsellipsis div:nth-child(1) {
  left: -3px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsellipsis div:nth-child(2) {
  left: -3px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(3) {
  left: 10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsellipsis div:nth-child(4) {
  left: 20px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
