.container {
  padding-bottom: 5rem;
}

.form {
  box-sizing: border-box;
}
.results {
  margin-top: 1rem;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.resultsList {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding-bottom: 2rem;
  max-height: calc(100vh - 170px);
}
.empty {
  font-size: 0.875rem;
  color: var(--color-secondary);
}
