.content {
  box-sizing: border-box;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 7rem;
  img {
    max-width: 600px;
    border-radius: 2rem;
    margin: 0 auto;
    margin-top: 3rem;
  }
  h1 {
    font-size: 3.6rem;
    line-height: 4rem;
    font-weight: bold;
  }
  p {
    margin-top: 1.5rem;
    font-size: 1.4rem;
    color: var(--color-secondary);
    span {
      background-color: var(--color-primary);
      color: #fff;
    }
  }
}
.loaderContainer {
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 20px;
  margin-top: 2rem;
  background: var(--tabs-bg);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.box {
  box-sizing: border-box;
  max-width: 600px;

  margin: 0 auto;
  margin-top: 5rem;
}
.logItem {
  padding-left: 1.5rem;
  border-left: 2px solid var(--color-secondary);
  position: relative;
  padding-bottom: 2rem;
  &:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 8px;
    left: -7px;
    background-color: var(--color-primary);
    border-radius: 50%;
  }
}
.logItemDate {
  font-size: 0.625rem;
  opacity: 0.6;
}
.logItemTitle {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.logItemDescription {
  color: var(--color-secondary);
}

.content {
  max-width: 720px;
  margin: 0 auto;
  margin-top: 1rem;
  position: relative;
  z-index: 2;

  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 5rem;
  a {
    color: var(--color-primary);
  }
  ul {
    margin-top: 1rem;
    list-style: circle;
    color: var(--color-text);
    li {
      &:before {
        content: '– ';
      }
    }
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.6rem;
  }
  h2 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 2rem;
  }
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.content ul,
.content ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.content ol {
  list-style: numeric;
}
.content li + li {
  margin-top: 0.5rem;
}
