.container {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  & > * {
    flex-grow: 1;
  }
}
.box {
  margin-bottom: 1rem;
}
.details {
  font-size: 0.675rem;
  font-weight: 600;
  color: var(--color-secondary);
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.couponContainer {
  margin-top: 0.5rem;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 8px;
  background-color: var(--color-primary);
  color: #fff;
  position: relative;
}
.action {
  margin-top: 1rem;
}
.couponTitle {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.info {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
}
.action {
  button {
    width: 100%;
    background: #fff;
    text-align: center;
    justify-content: center;
    span {
      color: var(--color-primary);
    }
  }
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
