.item {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: var(--border-radius-s);
  background-color: #111;
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  cursor: pointer;
  &:hover {
    border-color: var(--color-primary);
  }
}
.selected {
  border-color: var(--color-primary);
}
.itemHeader {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding-bottom: 0.4rem;
  border-bottom: 1px solid var(--color-card-border);
  a {
    &:hover {
      color: var(--color-primary);
    }
  }
  span {
    font-weight: 500;
    display: block;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    color: var(--color-secondary);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.itemContent {
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--color-secondary);
  span {
    font-family: inherit;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
  }
}

.itemUserInput {
  line-height: 1.4;
  max-height: 67px;
  overflow: hidden;
  span {
    font-family: inherit;
    background: rgba(135, 131, 120, 0.15);
    color: #eb5757;
    border-radius: 3px;
    font-size: 85%;
    padding: 0.2em 0.4em;
  }
}
.subtitle {
  margin-top: 0.5rem;
  color: var(--color-secondary);
}
.itemContentImages {
  height: 150px;
  max-height: 150px;
  overflow: hidden;
  display: grid;
  gap: 0.2rem;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 0.2rem), 2fr));
}
.itemDate {
  font-size: 0.75rem;
  color: var(--color-secondary);
  display: flex;
  justify-content: space-between;
}
.model {
  font-weight: bold;
  font-size: 0.725rem;
  color: var(--color-secondary);
}
