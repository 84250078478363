.select {
  position: relative;
}

.selectedContainer {
  box-sizing: border-box;
  padding-top: 0.5rem;
  padding-right: 1.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.5rem;
  background: var(--color-input-bg);
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 20px;
  border: 1px solid var(--color-border-input);
  color: var(--color-text);
  box-shadow: var(--shadow-input);
  position: relative;
  cursor: pointer;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-height: 38px;
  z-index: 2;
}
.empty {
  width: 100%;
  font-size: 0.875rem;
  color: var(--color-secondary);
}
.icon {
  position: absolute;
  pointer-events: none;
  top: 0.6rem;
  right: 1rem;
}
.dropdown {
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  box-sizing: border-box;
  top: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 48px;
  padding-right: 1.75rem;
  padding-left: 0.75rem;
  right: 0;
  left: 0;
  padding-bottom: 0.5rem;
  background: var(--color-input-bg);
  border: 1px solid var(--color-border-input);
  max-height: 260px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.title {
  font-weight: 500;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 158px;
}
.description {
  color: var(--color-secondary);
  font-size: 0.825rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
  max-width: 158px;
}
.pinItem {
  position: relative;
  &:hover {
    opacity: 0.6;
  }
}
.selected {
  position: absolute;
  top: 15px;
  width: 20px;
  left: 155px;
}
